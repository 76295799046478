import axios from 'axios'
import swal from 'sweetalert2'

/**
 * Axios config
 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (401 === error.response.status) {
    swal({
      title: 'Session Expired',
      text: 'Your session has expired. Would you like to be redirected to the login page?',
      type: 'warning',
      confirmButtonColor: '#f97304',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    }).then(function() {
      window.location = '/logout';
    });
  }

  return Promise.reject(error);
});

export { axios }
